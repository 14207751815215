import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { IApiResponse } from '@/shared/types/app';
import { IPaymentMethods } from './interfaces/payment-methods.interface';

export const paymentsMethodsApi = createApi({
  reducerPath: 'paymentsMethodsApi',
  baseQuery: customFetchBase,
  endpoints: build => ({
    getStripeSecretKey: build.query<string, null>({
      query() {
        return {
          url: '/stripe-api/get-public-key',
          method: 'GET',
        };
      },
      transformResponse: (result: IApiResponse<string>) => {
        return result.data;
      },
    }),
    getPaymentTypes: build.query<IPaymentMethods.Item[], null>({
      query() {
        return {
          url: '/b2b-offers/payment-methods/list',
          method: 'GET',
        };
      },
      transformResponse: (result: IApiResponse<IPaymentMethods.Item[]>) => {

        return result.data;
      },
    }),
    attachPaymentMethod: build.mutation<IPaymentMethods.Item, IPaymentMethods.PaymentMethodId> ({
      query: data => ({
        url: '/b2b-offers/payment-methods/attach',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<IPaymentMethods.Item>) => {
        return result.data;
      },
    }),
    detachPaymentMethod: build.mutation<IPaymentMethods.Item, IPaymentMethods.PaymentMethodId> ({
      query: data => ({
        url: '/b2b-offers/payment-methods/detach',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<IPaymentMethods.Item>) => {
        return result.data;
      },
    }),
    setAsDefaultPaymentMethod: build.mutation<IPaymentMethods.Item, IPaymentMethods.PaymentMethodId> ({
      query: data => ({
        url: '/b2b-offers/payment-methods/set-as-default',
        method: 'POST',
        body: data,
      }),
      transformResponse: (result: IApiResponse<IPaymentMethods.Item>) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetPaymentTypesQuery,
  useGetStripeSecretKeyQuery,
  useAttachPaymentMethodMutation,
  useDetachPaymentMethodMutation,
  useSetAsDefaultPaymentMethodMutation,
} = paymentsMethodsApi;
