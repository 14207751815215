import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/**
 * API
 * */
import { usersApi } from './api/users/users';
import { accountApi } from './api/account/account';
import { authApi } from './api/auth/auth';
import { subscriptionApi } from './api/subscription/subscription';
import { walletApi } from './api/wallet/wallet';
import { countryApi } from './api/countries/counties';
import { appApi } from './api/app/app';
import { dashboardApi } from './api/dashboard/dashboard';
import { paymentsMethodsApi } from './api/payment-methods/payment-methods';

/**
 * Features
 * */
import userReducer from './features/user/slice';
import appReducer from './features/app/slice';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [paymentsMethodsApi.reducerPath]: paymentsMethodsApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    userState: userReducer,
    app: appReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      accountApi.middleware,
      usersApi.middleware,
      subscriptionApi.middleware,
      paymentsMethodsApi.middleware,
      countryApi.middleware,
      appApi.middleware,
      walletApi.middleware,
      dashboardApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
